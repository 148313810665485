<template>
  <div class="project sub">
    <div class="hero animation-hero">
      <div class="hero-image project">
        <img src="../assets/subpage-bg-6.jpg" alt="" />
      </div>
      <div class="hero-text">
        <div class="page-grid">
          <h1>Industrial Cut</h1>
        </div>
      </div>
    </div>
    <div class="animation-wrapper">
      <div class="container">
        <div class="page-grid">
          <div class="text-block">
            <p>
              <span class="intro-letter">O</span>n this page you can see some
              protocols for technical patterns and grading of garments in 1:4.
            </p>

            <p>
              The pattern of a design is measured, prepared and drawn in order
              to create industrial pattern which contains all information for
              the seamstress to sew the garment in a very quick and rational
              way.
            </p>
          </div>
          <div class="image-block landscape">
            <div class="inner-image">
              <img src="../assets/industry/industrial-cut-1.jpg" alt="" />
            </div>

            <div class="caption">Industrial Pattern Vest 1</div>
          </div>
          <div class="text-block">
            <p>
              That way the garments can also be re-produced very easily. In
              order to do this, the procedure for the design of the patterns and
              the final elaboration of the technical garment must be followed
              correctly. Grading on the other hand is the procedure which
              creates the same technical pattern in different sizes. Hereby you
              calculate the difference of the sizes in millimetres (mm) with the
              help of measurement tables.
            </p>
          </div>

          <div class="image-block landscape">
            <div class="inner-image">
              <img src="../assets/industry/industrial-cut-2.jpg" alt="" />
            </div>

            <div class="caption">Industrial Pattern Vest 2</div>
          </div>
          <div class="text-block">
            <p>
              You need expertise and an excellent eye to change the pattern with
              the help of complex rules and distribute the difference in
              millimetres across different body shapes.
            </p>
            <p>
              It takes years of experience to perfect this technique. I would
              even call it an art in itself.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "trousers-project",
};
</script>

<style>
</style>

