<template>
  <div class="project sub">
    <div class="hero animation-hero">
      <div class="hero-image project">
        <img src="../assets/subpage-bg-4.jpg" alt="" />
      </div>
      <div class="hero-text">
        <div class="page-grid">
          <h1>Sleeve Project</h1>
        </div>
      </div>
    </div>
    <div class="animation-wrapper">
      <div class="container">
        <div class="page-grid">
          <div class="text-block">
            <p>
              <span class="intro-letter">H</span>ere I re-created a sleeve from
              the Valentino silk blouse below.
            </p>
          </div>
          <div class="image-block">
            <div class="inner-image">
              <img src="../assets/sleeve/sleeve-original.jpg" alt="" />
            </div>

            <div class="caption">Orginal Valentino Blouse</div>
          </div>
          <div class="text-block">
            <p>
              I used a mixture of flat cutting for the upper sleeve from a basic
              sleeve pattern. I used a so-called T-sleeve pattern which has a
              more flat crown and already a much broader shape.
            </p>
            <p>
              I made the sleeve crown higher and found the volume of the lower
              sleeve by draping and ruching the sleeve on to the upper part.
            </p>
          </div>
          <div class="image-block large">
            <div class="inner-image">
              <img src="../assets/sleeve/sleeve.jpg" alt="" />
            </div>
          </div>

          <div class="text-block">
            <p>
              Usually I try several times creating toiles until I am happy with
              the shape.
            </p>
            <p>
              Thereby adjusting the basic pattern constantly by measuring the
              toile and transferring the newshape and size onto it.
            </p>
          </div>
          <div class="image-block large">
            <div class="inner-image">
              <img src="../assets/sleeve/sleeve-studio-view.jpg" alt="" />
            </div>

            <div class="caption">Sleeve on Stand</div>
          </div>
          <div class="text-block">
            <p>I think I have done quite well with this ;)</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "trousers-project",
};
</script>

<style>
</style>

