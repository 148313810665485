<template>
  <div class="project sub">
    <div class="hero animation-hero">
      <div class="hero-image project">
        <img src="../assets/subpage-bg-3.jpg" alt="" />
      </div>
      <div class="hero-text">
        <div class="page-grid">
          <h1>Skirt Project</h1>
        </div>
      </div>
    </div>
    <div class="animation-wrapper">
      <div class="container">
        <div class="page-grid">
          <div class="text-block">
            <p>
              <span class="intro-letter">P</span>attern development of a midi
              skirt toile from the flat basic pattern of a skirt. This skirt is
              my own design.
            </p>
          </div>

          <div class="image-block large">
            <div class="inner-image">
              <img src="../assets/skirt/skirt-front.jpg" alt="" />
            </div>
            <div class="caption">A midi skirt toile</div>
          </div>
          <div class="text-block">
            <p>
              I developed a pattern with a slight A-line by using an I-cut to
              create the line.
            </p>
            <p>I also incorporated a yoke.</p>
            <p>
              By adding two pleats at the front and back each I created volume
              and texture to the skirt to create a stylish silhouette.
            </p>
          </div>
          <div class="image-block twin">
            <div class="inner-image">
              <img src="../assets/skirt/skirt-detail-top.jpg" alt="" />
            </div>
            <div class="inner-image">
              <img src="../assets/skirt/skirt-detail-bottom.jpg" alt="" />
            </div>
            <div class="caption">Skirt Detail Top</div>
            <div class="caption">Skirt Detail Bottom</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "skirt-project",
};
</script>

<style>
</style>

