<template>
  <div class="project sub">
    <div class="hero animation-hero">
      <div class="hero-image project">
        <img src="../assets/subpage-bg-1.jpg" alt="" />
      </div>
      <div class="hero-text">
        <div class="page-grid">
          <h1>Jacket Project</h1>
        </div>
      </div>
    </div>
    <div class="animation-wrapper">
      <div class="container">
        <div class="page-grid">
          <div class="text-block">
            <p>
              <span class="intro-letter">T</span>he tailored jacket is one of
              the crown jewels of pattern cutting and the cut of a jacket shows
              your knowledge as a pattern maker.
            </p>
          </div>
          <div class="image-block twin">
            <div class="inner-image">
              <img src="../assets/jacket/jacket-design.jpg" alt="" />
            </div>
            <div class="inner-image">
              <img src="../assets/jacket/jacket-front-detail.jpg" alt="" />
            </div>

            <div class="caption">Jacket Design</div>
            <div class="caption">Jacket Front Detail</div>
          </div>

          <div class="text-block">
            <p>
              I usually start with a design. Then I take the needed body
              measurements and create the basic jacket pattern.
            </p>
            <p>
              With up to three toiles I keep correcting the basic pattern for
              the jacket concentrating on the fit and shape, the size and angle
              of the shoulders, the position of the darts and the perfect
              joining of the different pattern pieces and eventually the
              position and fall of the two-part sleeve.
            </p>

            <p>
              Once I am happy with my basic cut I start the process of pattern
              development.
            </p>
            <p>
              By using thin stripes of a coloured sticky tape I almost draw the
              positions and shapes of the darts, pockets, buttons and the lapel.
            </p>

            <p>
              This project is ongoing, so please watch this space for new
              pictures illustrating my progress.
            </p>
          </div>
          <div class="image-block twin">
            <div class="inner-image">
              <img src="../assets/jacket/jacket-side-detail.jpg" alt="" />
            </div>
            <div class="inner-image">
              <img src="../assets/jacket/jacket-front-update.jpg" alt="" />
            </div>
            <div class="caption">Jacket Side Detail</div>
            <div class="caption">Jacket Front</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "project-3",
};
</script>

<style>
</style>

