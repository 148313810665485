<template>
  <div class="project sub">
    <div class="hero animation-hero">
      <div class="hero-image project">
        <img src="../assets/subpage-bg-4.jpg" alt="" />
      </div>
      <div class="hero-text">
        <div class="page-grid">
          <h1>Trousers Project</h1>
        </div>
      </div>
    </div>
    <div class="animation-wrapper">
      <div class="container">
        <div class="page-grid">
          <div class="text-block">
            <p>
              <span class="intro-letter">F</span>or this project I chose a
              high-waisted wide pair of shorts with front pleats.
            </p>

            <p>
              I interpreted the design and decided to do long trousers with cuff
              hems instead.
            </p>
            <p>
              I used an I-cut to widen the legs and calculated the depth and the
              position of the pleats.
            </p>
          </div>
          <div class="image-block twin">
            <div class="inner-image">
              <img src="../assets/trousers/short.jpg" alt="" />
            </div>

            <div class="inner-image">
              <img src="../assets/trousers/trousers-drawing.jpg" alt="" />
            </div>

            <div class="caption">Inspiration</div>
            <div class="caption">Design</div>
          </div>

          <div class="image-block jumbo">
            <div class="inner-image">
              <img src="../assets/trousers/trousers.jpg" alt="" />
            </div>

            <div class="caption">Marlene Trousers</div>
          </div>

          <div class="image-block twin">
            <div class="inner-image">
              <img src="../assets/trousers/trousers-front.jpg" alt="" />
            </div>
            <div class="inner-image">
              <img src="../assets/trousers/trousers-detail.jpg" alt="" />
            </div>

            <div class="caption">Front</div>
            <div class="caption">Back</div>
          </div>
          <div class="text-block">
            <p>
              The difficulty was to keep a nice fitting shape around the back
              and hips while having extremely wide Marlene-Dietrich kind trouser
              legs.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "trousers-project",
};
</script>

<style>
</style>

