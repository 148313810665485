<template>
  <div class="project sub">
    <div class="hero animation-hero">
      <div class="hero-image project">
        <img src="../assets/subpage-bg-2.jpg" alt="" />
      </div>
      <div class="hero-text">
        <div class="page-grid">
          <h1>Dress Project</h1>
        </div>
      </div>
    </div>
    <div class="animation-wrapper">
      <div class="container">
        <div class="page-grid">
          <div class="text-block">
            <p>
              <span class="intro-letter">C</span>reating a toile sample dress
              from a chosen picture of a designer dress.
            </p>
          </div>
          <div class="image-block twin">
            <div class="inner-image">
              <img src="../assets/dress/dress-front.jpg" alt="" />
            </div>
            <div class="inner-image">
              <img src="../assets/dress/dress-original.jpg" alt="" />
            </div>
          </div>
          <div class="text-block">
            <p>
              Development of the dress pattern from a basic dress pattern
              combining flat pattern cutting and cutting-on-stand draping.
            </p>
          </div>

          <div class="image-block twin">
            <div class="inner-image">
              <img src="../assets/dress/dress-detail-front.jpg" alt="" />
            </div>
            <div class="inner-image">
              <img src="../assets/dress/dress-detail-back.jpg" alt="" />
            </div>
            <div class="caption">Dress Detail Front</div>
            <div class="caption">Dress Detail Back</div>
          </div>
          <div class="text-block">
            <p>
              I interpreted the cut of the dress by creating a high-waisted
              bodice from the basic dress pattern and then draped the skirt on
              the manequin while creating a large front box pleat and four
              smalles box pleats at the bodice-skirt seam.
            </p>
          </div>
          <div class="image-block jumbo">
            <div class="inner-image">
              <img src="../assets/dress/dress-studio-view.jpg" alt="" />
            </div>
            <div class="caption">Lovely studio view of Hamburg</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "project-1",
};
</script>

<style>
</style>

