<template>
  <div class="about sub">
    <div class="hero animation-hero">
      <div class="hero-image about">
        <img src="../assets/bg-about.jpg" alt="" />
      </div>
    </div>
    <div class="animation-wrapper">
      <div class="container">
        <div class="page-grid">
          <div class="text-block aside">
            <div class="about-header">
              <h1>My story</h1>
            </div>
          </div>
          <div class="text-block">
            <h4>Hi! I am Rajmund.</h4>
            <p>
              I am realizing a long held ambition of pursuing a certificate in
              <b>fashion pattern cutting at AMD</b>, Akademie Fashion & Design
              and becoming a <b>pattern cutter and technical designer</b>.
            </p>
            <p>
              All my adult life I have worked in
              <b>fashion related jobs</b> ranging from <b>sales manger</b> on
              the shopfloor, <b>export service administrator</b> for a big
              fashion brand in London to <b>fashion editor</b> and writing for a
              luxury online retail start up to
              <b>styling fashion shoots for magazines</b>.
            </p>
            <p>
              I started out studying
              <b>Costume Design at Wimbledon College of Arts</b> in England
              where I gained a wealth of knowledge about
              <b>fashion history and historic garments</b> and the design
              process.
            </p>

            <p>
              I was always fascinated by the <b>construction of garments</b>,
              how the cut is put together, sculpting the fabric on the body,
              creating clothes which fit perfectly and look stunning.
            </p>
            <p>
              My lifelong passion and interest in the <b>art of fashion</b> and
              my desire to understand the construction and
              <b>craft of clothes-making</b>
              finally lead me to <b>taking the leap into pattern cutting</b>.
            </p>
            <p>
              <b
                >I love working with designers and fashion makers to create the
                designer's vision and translate it into wearable clothes</b
              >.
            </p>
            <p>
              It also has to be said that I very much flourish doing
              <b>organizational work</b> that involves
              <b>handling multiple projects and people</b>.
            </p>
            <p>
              Simply speaking, I am
              <b>a passionate lifelong fashion nerd</b> with
              <b>an amazing ability to spot trends</b>, a great
              <b>eye for design, shapes and proportion</b> and a
              <b>true commitment to learn</b>.
            </p>
          </div>

          <div class="text-block aside">
            <div class="about-header">
              <h4>Software Skills</h4>
            </div>
          </div>
          <div class="text-block">
            <ul>
              <li>
                GRAFIS
                <small
                  >(professional CAD software for pattern construction)</small
                >
              </li>
              <li>Clo3D</li>
              <li>Adobe Illustrator</li>
              <li>Adobe Photoshop</li>
              <li>MS Word</li>
              <li>MS Excel</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "about",
};
</script>

<style>
.about-header h1 {
  text-align: left;
  text-transform: none;
  line-height: 0.75;
  margin-top: 0.5rem;
  letter-spacing: 0.01rem;
  font-size: 3.5rem;
  font-weight: 600;
}

b {
  font-weight: 700;
}

.about h3 {
  letter-spacing: 0.1rem;
  font-weight: 700;
}
.about h4 {
  letter-spacing: 0.01rem;
}

.sub.about .hero-image img {
  object-position: center center;
}

@media screen and (max-width: 768px) {
  .about-header h1 {
    font-size: 3rem;
  }
}
</style>
